@use "../abstract" as *;

.guide-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include screen-xs() {
        height: 100%;
        width: 100%;
        justify-content: space-between;
    }
    @include screen-sm() {
        position: absolute;
        justify-content: flex-start;
        right: 0px;
        height: 100%;
        width: 40%;
        background-color: $kk-black-transparent-heavy;
        border-radius: $border-radius-mini 0 0 $border-radius-mini;
    }
    @include screen-lg () {
        width: 30%;
    }
    &__job-title {
        h1 {
            letter-spacing: 3px;
            color: $kk-white-light;
            text-align: center;
        }
        @include screen-xs() {
            display: none;
        }
        @include screen-sm() {

        }
    }
    &__task-title {
        max-width: 80%;
        font-size: 30px;
        @include screen-sm() {
            height: 10%;
            margin: 5%;
        }
    }
    #badge-wrapper {

        @include screen-xs() {
            justify-self: center;
            align-self: center;
        }
        @include screen-sm() {
            height: calc(max(10%, 60px));
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
            max-width: 100%;
            flex-shrink: 2;
        }
    }
    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: $border-radius-small $border-radius-small 0 0;
        @include screen-xs() {
            height: 30%;
            background-color: $kk-black-transparent;
            justify-content: space-evenly;
            padding: 5% 2% 0 2%;
        }
        @include screen-sm() {
            height: calc(max(60%, 400px));
            margin: 20px 0;
            justify-content: space-around;

        }
    }
    &__check-values {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        @include screen-sm() {
            width: 90%;
        }
    }
    &__info {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        max-height: 70%;
        @include screen-sm() {
            bottom: 30px;
            flex-direction: column;
            align-items: center;
            height: 60%;
            justify-content: space-around;

        }
        img {
            @include screen-xs() {
                transform: scale(0.7);
            }
            // margin-left: 5%;
        }
    }
    &__task-description {
        overflow-x: scroll;
        @extend %hide-scrollbar;
        font-size: 25px;
        padding: 5px;
        line-height: normal;
        @include screen-xs(){
            width: 70%;
        }
        @include screen-sm() {
            margin: 10px 0;
            border: 2px solid $kk-white-light;
            border-radius: $border-radius-standard;
            height: calc(max(100%, 120px));
            font-size: 1em;
            width: 90%; 
        }
    }
}
