@use "../abstracts/" as *;

.quick-route {
    @include list-component(200px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $kk-orange-main !important;
    text-align: center;
    text-decoration: none !important;
    font-size: 1.8rem;
    font-weight: 500;
    flex-direction: column;

    :hover {
        text-decoration: none !important;
        color: $kk-orange-main !important;
        transition: transform 300ms;
    }

    svg {
        max-height: 40%;
        margin-left: 10px;
        fill: $kk-orange-main;
    }

    &__model-access {
        display: flex;
        flex-direction: row;
        :hover {
            transform: scale(1.1);
        }
        a {
            text-align: center;
            font-size: 0.8em;
            // width: 70px;
            border: 2px solid $kk-white-light;
            border-radius: $border-radius-standard;
            margin: 0 5px;
            padding: 5px;
        }
    }
}

.active {
    p {
        &:hover {
            transform: scale(1.1);
        }
    }
}

.altered {
    display: flex;
    flex-direction: column !important;
}
