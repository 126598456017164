@use "../abstracts/" as *;

#camera-button-wrapper {
  z-index: 8;
}
.photo-button {
  @include screen-xs() {
    bottom: 15%;
    left: 50%;
  }
  @include screen-sm {
    top: 50%;
    left: 15%;
  }
  width: 90px;
  height: 90px;
  margin-top: -50px;
  margin-left: -50px;
  position: absolute;
  z-index: 7;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.circle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 12%;
  left: 12%;
  bottom: 12%;
  right: 12%;
  border-radius: 100%;
  background-color: #ffffff;
  font-weight: bolder;
  opacity: 1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ring {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  border-radius: 100%;
  border: 0.5em solid #ffffff;
  opacity: 0.8;
}

// .photo-button .circle,
// .photo-button .ring {
//   transition: all 0.25s;
// }
// .photo-button:hover .circle {
//   opacity: 1;
// }
// .photo-button:active .ring {
//   opacity: 1;
// }
// .photo-button:active .circle {
//   opacity: 0.5;
// }
