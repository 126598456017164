@use "../abstracts/" as *;

#add-part-button {
    scroll-behavior: smooth;
    display: flex;
    flex-direction: row;
    @include screen-xs() {
        height: 100%;
    }
    @include screen-sm() {
        width: 100%;
    }
    &__input {
        @extend %addButtonSettings;
        @include screen-xs() {
            height: 100%;
            width: 80%;
            width: 3rem;
        }
        @include screen-sm() {
            width: 100%;
            height: $label-component-height;
            margin: $gap-between-components 0;
        }
        // transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    &__submit {
        position: relative;
        border: 1px solid black;
        transform: translateX(-8px);
        
    }
    .max{
        color: #fff;
        background-color: $kk-orange-main;
        @include screen-xs() {
            height: 40px;
            width: 100%;
        }
    }

    .disabled{
        background-color:  $kk-orange-disabled;
        border: 3px solid $kk-orange-disabled;
        box-shadow: none;
        cursor: default;
        text-align: center
    }
}

.zoom {
    @include screen-xs() {
        top: 40%;
        left: 50%;
        width: 85vw !important;
    }
    @include screen-sm() {
        top: 200px;
        left: 50%;
        width: 50vw !important;
    }
    align-items: center;
    position: fixed;
    height: 40px;

    transform: translate(-50%, -50%);
    z-index: 60;
}
