// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

* header {
    display: flex;
    position: relative;
    height: $header-height;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    
    letter-spacing: 3px;
    color: $kk-white-light;
    
    // For debugging
    // border: 4px solid yellow;
}