@use "../abstracts/" as *;

.selectedLabelId {
    background-color: $kk-lightblue;
    border: 3px solid $kk-yellow-accent;
}
.highlightedLabel {
    background-color: $kk-green-accent;
}

.untrained {
    border: 3px solid $kk-untrained-warning-accent !important;
}

.cl-label {
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    height: $label-component-height;
    display: grid;
    grid-template: 100% / 1fr 3fr 1fr;
    grid-template-areas: "selection-button input remove-button";
    justify-items: center;
    align-items: center;
    padding: 3pt 0;
    border-radius: $border-radius-standard;
    border: 2px solid $kk-yellow-accent;
    //background-color:$labelBackground;
    @include screen-xs() {
        width: 65vw;
        margin-left: 2px;
        margin-right: 2px;
    }
    @include screen-sm() {
        margin: 10px 0px;
        height: 40px;
    }

    &__selection-button {
        grid-area: selection-button;
        width: $nav-size-small * 0.8;
        height: $nav-size-small * 0.8;
        background-color: $kk-black-transparent;
        border-radius: 50%;
        padding: 5px;
        margin: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: filter 300ms;
        cursor: pointer;
        position: absolute;

        &:hover {
            filter: brightness(1.5);
        }

        svg {
            fill: $kk-orange-main;
            width: $nav-size-small * 0.7;
            height: $nav-size-small * 0.7;
            position: absolute;
        }
    }

    input {
        grid-area: input;
        width: 100%;
        size: 10;
        margin: 1pt;
        height: 100%;
        color: white;
        font-weight: bold;
        border-radius: 5pt;
        background: transparent;
        border: transparent;
        text-align: center;
        &:focus {
            outline: none;
        }
        @include screen-xs(){
            font-size: 15pt;
        }
        @include screen-sm(){
            font-size: 13pt;
        }
    }

    &__remove-button {
        grid-area: remove-button;
        font-size: 110%;
        border-radius: 3px;
        background: transparent;
        border: transparent;
        padding: 0.6%;
        text-align: center;
        &:hover {
            color: #fff;
            background-color: $kk-black-transparent;
            font-weight: bold;
        }
        padding: 0.5rem;
        position: absolute;
        right: 3px;
        border-radius: $border-radius-medium;
    }
}
