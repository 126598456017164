@import "../abstracts/functions.scss";
@import "../abstracts/mixins";
@import "../abstracts/placeholders";
@import "../abstracts/variables";

#label-bar {
    //overflow:hidden;
    display: flex;
    background-color: $kk-black-transparent-heavy;
    overflow: scroll;
    z-index: 12;
    @extend %hide-scrollbar;
    @include screen-xs() {
        position: relative;
        top: 1%;
        height: 3.5rem;
        width: 99%;
        align-items: center;
        z-index: 9;
        gap: 4px;
        padding: 3pt;
        border-radius: $border-radius-standard;
    }
    @include screen-sm {
        position: absolute;
        right: 0px;
        width: 40vw;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px;
        border-radius: #{$border-radius-mini} 0 0 #{$border-radius-mini};
        padding-bottom: 80px;
    }
    @include screen-lg(){
        width: calc(min(30vw, 300px));
    }
    &__classification-labels {
        @extend %hide-scrollbar;
        display: inline-flex;
        z-index: 10;
        @include screen-xs() {
            border-radius: $border-radius-mini;
            align-items: center;
            flex-grow: 0;
            flex-shrink: 1;
            overflow-x: auto;
            overflow-y: hidden;
            height: 100%;
            width: 95%;
        }
        @include screen-sm(){
            flex-direction: column;
            overflow-y: scroll;
        }
        // &:hover{
        //     background-color:$kk-grey-transparent;//$kk-yellow-accent;
        //     }
    }

    .ownTooltip {
        position: absolute;
        font-size: 20px;
        background-color: $kk-white-light;
        color: $kk-white-light;
        padding: 5px 8px;
        border-radius: 10px;
        width: 10rem;
        right: 5%;
        box-shadow: 0 10px 10px $kk-black-light;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .ownTooltip::before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        background-color: $kk-white-light;
        top: -3px;
        right: 15%;
        transform: translate(-50%) rotate(45deg);
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .icon:hover .ownTooltip {
        top: 25px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    .icon:hover span,
    .icon:hover .ownTooltip {
        text-shadow: 0px -1px 0px $kk-black-light;
    }

    .add:hover,
    .add:hover .ownTooltip,
    .add:hover .ownTooltip::before {
        background-color: $kk-orange-main;
        border-color: $kk-orange-main;
        color: $kk-white-light;
    }

}
