@use "../abstracts/" as *;

.scroll-body-wrapper {
    display: flex;
    position: absolute;
    height: 98%;
    width: 95%;
    flex-direction: column;
    justify-content: flex-start;
    .scroll_body {
        width: 100%;
        height: 100%;
        @include screen-xs {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;   
        }
        @include screen-sm {
            display: grid;
            margin-top: 5px;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: 300px;
            gap: $gap-between-components;
        }
        @include screen-lg {
        }
        @extend %hide-scrollbar;
    }
}
