@use "../abstract" as *;

#quick-trainer {
    z-index: 15;
    @include screen-xs() {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}