@use "../abstract" as *;

.task-editable {
    @include list-component($height: 300px, $gap-size: 15px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 2%;
    &__header {
        height: 15%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        // margin-bottom: $gap-in-components-items;
        .task-id {
            background-color: $kk-grey-light;
            padding-left: 5px;
            width: 75%;
            border: none;
            font-size: 2em;
            color: $kk-orange-main;
            border-radius: $border-radius-standard;
        }
    }
    &__info {
        height: 20%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 80px;
        .task-text {
            background-color: $kk-grey-light;
            border: 1px solid $kk-white-light !important;
            border-radius: $border-radius-standard;
            padding-left: 5px;
            padding-top: 5px;
            width: 75%;
            font-size: 1.2em;
            color: $kk-orange-main;
            margin-right: 2%;
        }
        svg {
            height: 100%;
            width: auto;
            border: 1px solid $kk-orange-main;
            border-radius: $border-radius-standard;
            padding: 2px;
        }
    }
    &__model-wrapper {
        height: 12%;
        width: 100%;
        display: flex;
        justify-content: space-around;
        transform: scale(0.95);
        z-index: 15;
        .model-dropdown {
            z-index: 20;
            height: 100%;
            width: 250px;
            background-color: $kk-black-light;
            border: 2px solid $kk-grey-light;
            border-radius: $border-radius-standard;
            color: $kk-white-light;
            &::after {
                color: $kk-orange-main;
                text-align: justify;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    &__check-values {
        height: 30%;
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        overflow-y:visible;
        @extend %hide-scrollbar;
        z-index: 12;
    }
}
