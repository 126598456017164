// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
@use "../abstracts/" as *;

html,
body {
  height: 100%;
}
/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: $scrollbarFrontColor $scrollbarBackgroundColor;
  margin: 0;
  padding: 0;
  // scroll-behavior: smooth;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 0.5em;
  position: relative;
  top: 0;
}

*::-webkit-scrollbar-track {
  background: $scrollbarBackgroundColor;
  padding: 3px;
  margin: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbarFrontColor;
  border-radius: 20px;
  border: 2px solid $kk-orange-main;
}

/**
 * Adds red color to not trained Models
 */
.notExecutable {
  color: $kk-untrained-warning-accent !important;
}

.partName_untrained {
  color: $kk-untrained-warning-accent !important;
}


.popUpOpened {
  background-color: grey !important;
}

// From template

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $kk-orange-main;
  text-decoration: none;

  @include on-event {
    color: $kk-white-light;
    text-decoration: underline;
  }
}
/**
* Hides Component 
*/
.hideComponent {
  display: none;
}

hr {
  margin: 5px ​5px 0 5px !important;
  background-color: $kk-white-light;
  height: 2px !important;
}
