@use "../abstracts/" as *;

%temporary {
    display: flex;
    justify-content: center;
    width: 120px;
    height: 80%;
    letter-spacing: 0;
    font-size: 0.7rem;
    border-radius: 30px;
    padding: 0 5px;
    background-color: $kk-grey-light-transparent;
}

.connection-status {
    &--not-connected {
        @extend %temporary;
        border: 2px solid $kk-untrained-warning-accent;
    }
    &--connecting {
        @extend %temporary;
        border: 2px solid $kk-orange-main;
    }
    &--connected {
        @extend %temporary;
        border: 2px solid $kk-green-accent;
    }
    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        svg {
            width: 100%;
            height: 60%;
        }
    }
    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
    }
}
