@use "../abstracts/" as *;

.floating-input{
    z-index: 100;
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    @include screen-xs() {
        width: 80%;
    }
    @include screen-sm() {
        width: calc(min(80vw, 400px));
    }

}