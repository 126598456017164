@use "../abstract" as *;

#class-model-selector {
    height: 100%;
    width: 150px;
    margin-right: 10px;
    border-radius: 50px;
}
.dropdown-menu {
    width: 100% !important;
    max-height: 200px !important;
    overflow-y: scroll;
}
