@use "../abstracts/" as *;

#home-page {
    z-index: 5;
    &__settings {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 100%;
    }
    &__body {
        @include screen-sm() {
            grid-auto-rows: 200px;
        }
    }
}
