@use "../abstract" as *;

.check-button {
    width: calc(min(43%, 115px));
    padding: 0 2% 0 0;
    z-index: 10;
    margin: 5px 0;
    &__button {
        border-radius: 30px;
        width: 100%;
        height: 30px;
        display: flex;
        font-size: 0.8em;
        align-items: center;
        justify-content: center;
        background-color: $kk-black-light;
        border: 1px solid $kk-grey-light;
        color: $kk-orange-main;
    }
    .labelDropDownWrapper {
        width: 200px;
        flex-direction: row;
        right: 50px;
        position: absolute;
        justify-items: center;
        .labelDropDownListOverlay {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            .labelDropDownListItem {
                flex-direction: column;
                display: flex;
                position: relative;
                width: 100%;
            }
        }
    }
}

.add-check-button{
    margin: 5px 0;
    width: 50px;
    z-index: 8;
    &__button{
        border-radius: 30px;
        width: 100%;
        height: 30px;
        @extend %addButtonSettings;
        &::after {
            // remove caret
            display: none;
        }
        &:focus {
            outline: none;
            box-shadow: none;
            background-color: $kk-orange-main;
            border-color: $kk-orange-main;
        }
    }
}
