// -----------------------------------------------------------------------------
// This file includes all bootstrap adjustments
// -----------------------------------------------------------------------------
// Todo: Check font-sizes

.dropdown-menu {
    width: 200px;
    max-height: 140px;
    overflow-y: scroll;
}
.dropdown-item {
    font-size: 0.875rem;
}


#bs_contextInput {
    position: fixed;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    z-index: 5;
    color: $kk-grey-light;
}
#bs_contextInput.form-control {
    margin-left: 5px;
    width: 95%;
    background-color: $kk-grey-light;
    color: white;
}

#bs_contextInput.form-control:focus {
    outline-color: $kk-orange-main;
    box-shadow: 0 0 0 0.05rem $kk-orange-main;
}

#bs_xButton {
    position: absolute;
    top: 3px;
    right: 3px;
}

input.form-control {
    background-color: $kk-black-light;
    color: whitesmoke;
    border: none;
}

.bs_standardInputField.form-control {
    padding: 2px 10px;
    margin-left: 5px;
    width: 95%;
    background-color: $kk-grey-light;
}

.bs_standardInputField.form-control:focus {
    background-color: whitesmoke;
    box-shadow: none;
}
.bs_standardInputField.form-control:focus::placeholder {
    color: $kk-grey-light;
}
.bs_standardInputField.form-control::placeholder {
    color: whitesmoke;
    font-size: 0.875rem;
}


/**
* Delete Component Styles
*/

.bs_deleteButton {
    border-radius: $border-radius-small;
}

.bs_deleteButton svg * {
    fill: #dc3545;
}

.bs_deleteButton:hover svg * {
    fill: $kk-grey-light;
    transition: all 0.1s linear;
}

// *********** Nav Menu BS Styles *********** //


// *********** PopUp Menu BS Styles *********** //

.bs_inputInNavmenu.form-control {
    //border: 1px solid rgb(156, 156, 156)
    font-size: 1.5rem;
    font-weight: bold;
}

.bs_inputInNavmenu.form-control::value {
    display: block;
}

.bs_inputInNavmenu.form-control:focus {
    //border: 1px solid rgb(156, 156, 156)
    font-size: 1rem;
    font-weight: normal;
}

#bs_thresholdSlider {
    color: red;
}

#bs_popUp_addPart.form-control::placeholder {
    color: whitesmoke;
}

#bs_popUp_addPart.form-control:focus::placeholder {
    color: $kk-grey-light;
}

#bs_popUp_addPart.form-control {
    @extend %bs-form-control;
}

#bs_popUp_addPart.form-control:focus {
    @extend %bs-form-control-focus;
}

// *********** Overlapping *********** //

// %bs-form-control {
//     outline-color: $kk-orange-main;
//     box-shadow: 0 0 0 0.05rem $kk-orange-main;
//     background-color: whitesmoke;
//     color: $kk-black-light;
// }

