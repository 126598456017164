@use "../abstract" as *;

#guide-card-slider {
    // Don't know why they were there
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    // transition: 0.3s;
    &__header {
        h1 {
            @include screen-sm() {
                display: none;
            }
        }
    }
    &__body {
        z-index: 5;
        overflow: hidden;
        @include screen-xs() {
            position: absolute;
            top: $header-height;
            bottom: 0px;
            width: 100%;
            border: 4px solid $kk-white-light;
            border-radius: #{$border-radius-mini} / #{$border-radius-mini};
            &:hover {
                border-radius: 5%;
                transition: 2.3s;
            }
        }
        @include screen-sm() {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            width: 100vw;
        }

        // For debugging
        // border: 3px solid red;

    }
    &__video {
        z-index: 5;
        position: absolute;
        @include screen-xs() {
            bottom: 0%;
            width: 100%;
        }
        @include screen-sm() {
            height: 102%;
            bottom: -1%;
            border: 1px solid $kk-grey-light-transparent; 
        }

        /*
        When being in the GuideCardMode on iOS, eventually the video will flacker or even disappear. 
        Actually, I have no idea why this works, but the following line seems to stabilize that bug. */
    }
    &__carousel {
        z-index: 10;
        // border: 3px solid green;
    }
    &__carousel-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        h1 {
            @extend %placeholderH1;
        }
    }
}
