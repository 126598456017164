@use "../abstract" as *;


// Todo: test if switch from local "nav-size" to global "nav-size-big" has sideeffect. Switch if not.

:root {
  --nav-size: 80px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

span {
  color: $kk-white-light;
  text-decoration: none;
}

.classModel_untrained {
  color: $kk-untrained-warning-accent;
}

/* Top Navigation Bar */

/* <nav> */
.navbar_kk {
  // height: var(--nav-size);
  // background-color: transparent; //var(--bg);
  // padding: 0 1rem;
  // bottom: 10px;
  // right: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.loader {
  /* Light grey */
  border-top: 4px dotted $kk-orange-main;
  //border-left: 16px solid #D26B24; /* Blue */
  // border-left: 16px solid #76ABC9; /* Blue */
  border-right: 4px dotted $kk-lightblue; /* Blue */
  border-radius: 50%;
  --button-size: calc(var(--nav-size) * 0.6);
  width: var(--button-size);
  height: var(--button-size);
  animation: spin 2s linear infinite;
  z-index: 8;
  position: absolute;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
/* <li> */
.nav-item_kk {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: $kk-grey-transparent;
  border-radius: 50%;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  z-index: 9;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: $kk-white-light;
  width: 30px;
  height: 30px;
}

/* Dropdown Menu */

.dropdown_nav {
  position: absolute;
  top: 20px;
  width: 300px;
  height: 100px;
  transform: translateX(-55%);
  background-color: $kk-black-light;
  border: $border-standard-grey;
  border-radius: $border-radius-standard;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: height $standard-transition-speed ease;
  z-index: 10;
}

.menu {
  width: 100%;
  z-index: 10;
  position: absolute;
  max-height: 80vh;
  overflow-y: scroll;
  @extend %hide-scrollbar;
  .add-model-input {
    height: 30px;
    padding: 0.1rem;
    width: 90%;
    right: 0%;
    padding: 0 0.4rem;
    margin: 0.3rem 1rem 0.3rem 1rem;
    background-color: $kk-black-light;
    border: $border-standard-grey;
    border-radius: 0.25rem;
    color: $kk-white-light;
    &:focus {
    }
  }
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: $border-radius-mini;
  transition: background $standard-transition-speed;
  padding: 0.1rem;
  margin: 0.3rem 1rem 0.3rem 1rem;
  z-index: 10;
  &:hover {
    background-color: $kk-grey-transparent;
  }
  .icon-button {
    margin-right: 0.5rem;
    padding-left: 5;
    flex:none;
    &:hover {
      filter: none;
    }
    svg {
      transform: scale(0.9);
    }
    .white{
      fill: $kk-white-light;
    }
    .orange{
      fill: $kk-orange-main;
    }
  }
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all $standard-transition-speed ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all $standard-transition-speed ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all $standard-transition-speed ease;
}
.menu-secondary-exit {
  transform: translateX(-110%);
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all $standard-transition-speed ease;
}

#thresholdSlider {
  width: 60%;
  justify-self: center;
  align-self: center;
  position: fixed;
  z-index: 10;
  left: 20%;
  right: 20%;
  margin: auto;
}
.flexSliderLayout {
  display: flex;
  justify-content: center;
  align-content: center;
}

// ******* Popup Menu ******** //

.popUp_nav {
  position: absolute;
  // left: 50%;
  // top: 50%;
  bottom: calc(var(--nav-size) * 1);
  width: 250px;
  //max-height: 250px;
  background-color: $kk-black-light;
  border: $border-standard-grey;
  border-radius: $border-radius-mini;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0ms ease, height $standard-transition-speed ease;
  z-index: 10;
  justify-content: flex-start;
  .menu {
    position: absolute;
    overflow-y: scroll;
    max-height: 35vh;
  }
  .menu-item {
    height: 40px;
    padding: 0.1rem;
    margin: 0.2rem 0.2rem 0.2rem 0.2rem;
    .title {
      padding: 0;
    }
    .icon-button {
      transform: scale(0.8);
      font-size: 1rem;
    }
    .cl-result-pass {
      border: 2px solid;
      border-color: rgb(42, 104, 42);
    }
    .cl-result-crit {
      border: 2px solid;
      border-color: rgb(145, 145, 9);
    }
    .cl-result-fail {
      border: 2px solid;
      border-color: rgb(136, 3, 3);
    }
    .expandable {
      padding-right: 2vw;
    }
  }
}

/***** BackButton *****/

* .back-button {
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: transparent;
  border-color: $kk-orange-main;
  border-radius: $border-radius-medium;
  color: $kk-orange-dark;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.button-big {
  transform: scale(1.2);
}

.nav-button {
  display: flex;
  background-color: transparent;
  border: none;
  height: 35px;
  width: 35px;
  padding: auto;
  justify-content: center;
  align-items: center;
  svg {
    max-height: 40px;
    max-width: 40px;
    * {
      fill: $kk-orange-main !important;
    }
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}
