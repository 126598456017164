@use "../abstracts/" as *;

#sideSlider {
  left: 2%;
  height: 80%;
  align-self: center;
  position: absolute;
  z-index: 7;
}
#bottomSlider {
  bottom: 8%;
  width: 80%;
  position: absolute;
  z-index: 7;
}

.boundingBoxOverlay {
  position: absolute;
  background-color: transparent;
  .boundingBox {
    position: absolute;
    border: 3px solid $kk-green-accent;
    color: $kk-green-accent;
    text-align: left;
    z-index: 6;
    border-radius: 2%;
    animation: comeIn ease-in 0.5s;
    display: flex;
    align-items: flex-start;
    p {
      padding-left: 1%;
      font-size: 15pt;
      color: rgba(255, 255, 255, 0.7);
      background-color: rgba(12, 77, 3, 0.129);
    }
    @keyframes comeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

canvas {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid $kk-white-light;
  border-radius: 4px;
}
