@use "../abstract" as *;

.job-assistant {
    // position: absolute;
    // background-color: turquoise;
    height: 98%;
    top: 1%;
    width: 98%;
    // background-color:$transparentBackground;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 $kk-black-transparent;
    transition: 0.3s;
    border-radius: $border-radius-mini;
}