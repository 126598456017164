@use "../abstracts/" as *;

#video-placeholder {
    @extend %flex-center;
    z-index: 5;
    &__video-section {
        @extend %flex-center;
        z-index: 4;
        #text {
            @extend %placeholderH1;
        }
    }
}