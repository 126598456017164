@use "../abstract" as *;

#start-page {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &__background-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(#{$kk-background});
        height: 100%;
        width: 100%;
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        z-index: 0;
    }
    &__background-overlay {
        height: 100%;
        width: 100%;
        background-color: $transparentBackground;
        padding: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        h1 {
            background-color: #000;
            color: #fff;
        }
    }
    &__sub-menu {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 50;
        height: 50px;
        width: 50px;

    }
}
