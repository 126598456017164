@use "../abstract" as *;

#start-training-button {
    @include screen-xs() {
        position: relative;
        margin-top: 15px;
    }
    @include screen-sm {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
    width: 200px;
    height: 50px;
    border: 1px solid $kk-orange-main;
    border-radius: 50px;
    background-color: rgba($kk-orange-main, 0.7);
    z-index: 10;
    font-weight: 500;
    color: $kk-white-light;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &:hover {
        filter: brightness(1.2);
    }
    span {
        font-size: x-large;
        margin-right: 10px;
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.rotating {
    animation: rotating 2s linear infinite;
}
