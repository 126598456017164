// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Kitty Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// Mixin for mobile phones in portrait mode.
@mixin screen-xs {
  @media (max-width: map-get($breakpoints, "screen-sm")) {
    @content;
  }
}

/// Mixin for mobile phones in landscape mode.
@mixin screen-sm {
  @media (min-width: map-get($breakpoints, "screen-sm")) {
    @content;
  }
}

/// Mixin for tablets and bigger screens.
@mixin screen-lg {
  @media (min-width: map-get($breakpoints, "screen-lg")) {
    @content;
  }
}

/// Mixins for our standard components
@mixin list-component($height: 100px, $width: 100%, $gap-size: $gap-between-components, $clickable: false) {
  border-radius: $border-radius-standard;
  background-color: $kk-grey-light;
  border: 2px solid $kk-grey-light;
  box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
  @include screen-xs() {
    height: $height;
    width: $width;
    flex-shrink: 0;
    margin: $gap-size 0px;
  }
  @include screen-sm() {
  }

  @if $clickable {
    @include screen-sm() {
      cursor: pointer;
      // &:hover {
      //   background-color: lighten($kk-grey-transparent, 10%);
      // }
    }
  }
}

@mixin buttonSettings() {
  font-size: 110%;
  border-radius: 3px;
  background: transparent;
  border: transparent;
  padding: 0.6%;
  text-align: center;
  &:hover {
    color: #fff;
    background-color: $kk-black-transparent;
    font-weight: bold;
  }
}
