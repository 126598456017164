/* Works on Firefox */
@use "../abstracts/" as *;

#video-stream {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    max-height: 100%;
    position: absolute;
    height: 100%;
    bottom: 0%;
    overflow: hidden;
    // For debugging
    // border: 3px solid red;
    video {
        position: relative;
        @include screen-xs(){
            width: 100%;
        }
        @include screen-sm(){
            height: 100%;
        }
        // For debugging
        // border: 3px solid red;
        border-radius: 3%;
        background-color: transparent;
    }
}
