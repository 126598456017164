@use "../abstract" as *;

.home-button {
    background-color: $kk-grey-transparent;
    border-radius: 50%;
    border: $border-standard-grey;
    height: 100%;
    width: 50px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        filter: brightness(1.2);
    }
    svg {

    }
}

#apps-icon{
    fill: $kk-orange-main;
    transform: scale(0.8);
}