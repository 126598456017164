// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

$kk-background: "../../resource/kimoknow_background.png";

// ********** Color Palette ************** //

// Base Colors
$kk-black-light: rgb(26, 23, 23) !default;
$kk-white-light: rgb(216, 218, 223) !default;
$kk-grey-light: rgb(71, 74, 77) !default;

$kk-black-transparent: rgba(26, 23, 23, 0.2) !default;
$kk-black-transparent-heavy: rgba(26, 23, 23, 0.6) !default;
$kk-grey-transparent: rgba(107, 96, 96, 0.465) !default;
$kk-grey-light-transparent: rgba(62, 62, 62, 0.9) !default;

// Accent colors
$kk-orange-main: #f35e17 !default;
$kk-orange-dark: #b34f0c !default;
$kk-orange-disabled: #913b11 !default;
$kk-yellow-accent: #f3cd21 !default;
$kk-lightblue: #43a1ba !default;
$kk-untrained-warning-accent: #ff0202 !default;
$kk-green-accent: rgb(30, 150, 30) !default;

$scrollbarBackgroundColor: rgba(255, 106, 0, 0);
$scrollbarFrontColor: #86838375;
$transparentBackground: rgba(0, 0, 0, 0.7);

$nav-size-small: 40px;
$nav-size-big: 80px;

$speed: 250ms;

// ********** Border Types ************** //

/// Standard Grey Border
/// @type Radius
$border-standard-grey: 1px solid $kk-grey-light;

// **********  Border Radius ************** //

/// Standard Border Radius for Components
/// @type Radius
$border-radius-standard: 10px;

/// Mini Border Radius
/// @type Radius
$border-radius-mini: 5%;

/// Small Border Radius
/// @type Radius
$border-radius-small: 10%;

/// Medium Border Radius
/// @type Radius
$border-radius-medium: 20%;

/// Big Border Radius
/// @type Radius
$border-radius-big: 30%;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

// **********  Animation Settings ************** //
$standard-transition-speed: 250ms;

// **********  Sizes, Gaps & Margins ************** //
$gap-between-components: 15px;
$gap-in-components-items: 10px;
$header-height: 50px;
$label-component-height: 40px;

/// ********* Breakpoints *********
/// We use a mobile first approach and try to keep it simple as possible.
/// Therefore we define breakpoints only for 
/// Mobile Phones in Landscape mode and bigger screens, like tablets & desktops.
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "screen-sm": 576px, // For mobile phones in landscape mode.
  "screen-lg": 768px // For Tablets and bigger screens.
) !default;