@use "../abstract" as *;

.job-list {
    &__profiles {
        // Base Style is under _body > scroll_body
    }

    &__profile {
        @include list-component($height: 80px, $gap-size: 7px, $clickable: true);
        display: flex;
        flex-direction: column;
        @include screen-xs() {
            justify-content: center;
        }
        @include screen-sm() {
        }
        &-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            @include screen-sm() {
                padding: 15px 0;
                height: 35%;
            }
        }
        hr {
            margin: 0;
        }
        &-extended-info {
            @include screen-xs() {
                display: none;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            height: 90%;
            width: 100%;
        }

        &-picture {
            position: relative;
            max-width: 50px;
            max-height: 50px;
            margin-left: 10px;
        }

        &-name {
            color: $kk-white-light;
            width: 50%;
            font-weight: 600;
            letter-spacing: 0.64px;
            flex-grow: 2;
            padding: 0% 8%;
            text-align: left;
            @include screen-xs() {
                font-size: 18px;
            }

            @include screen-sm() {
                font-size: 20px;
            }
        }
        &-buttons {
            // margin-right: 10px;
            width: 25%;
            display: flex;
            justify-content: center;
            @include screen-sm() {
                flex-direction: column-reverse;
                align-items: center;
            }
            @include screen-lg() {
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
            }
            .bs_deleteButton {
                transform: scale(0.7);
            }
            .save-button {
            }
        }
        hr {
            @include screen-xs() {
                display: none;
            }
        }

        &__add {
            @extend %addButtonSettings;
            @include screen-xs() {
                width: 100%;
                height: 60px;
                margin: $gap-between-components 0px;
            }
            @include screen-sm() {
                height: 100px;
                width: 100px;
                justify-self: center;
                align-self: center;
            }
            font-size: 2em;
        }
    }

    &__input {
        width: calc(min(100%, 300px));
        background-color: transparent;
        color: white;
        margin: 0 10px;
        padding: 10px;
        border-color: #ff9921;
        border-radius: 4px;
    }
}
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 6;
}

.box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    top: 50%;
    background: #484a4d;
    border-radius: 4px;
    padding: 20px;
    color: #dadce1;
    border: 1px solid #999;
    overflow: auto;
}
