%bs-form-control {
    outline-color: $kk-orange-main;
    box-shadow: 0 0 0 0.05rem $kk-orange-main;
    background-color: whitesmoke;
    color: $kk-black-light;
}

%bs-form-control-focus {
    @extend %bs-form-control
}

%addButtonSettings {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $kk-orange-dark;
    color: $kk-white-light;
    font-size: 110%;
    border-radius: $border-radius-standard;
    border: 2pt solid $kk-orange-main;
    text-align: center;
    flex-grow: 0;
    font-weight: bold;
    box-shadow: 0px 5px 5px scale-color($kk-orange-dark, $alpha:-40%);
    text-align: center;
    cursor: pointer;
    &::placeholder{
        color: $kk-white-light;
    }
    &:hover {
        color: #fff;
        background-color: $kk-orange-main;
    }
    p {
        display: flex;
        flex-direction: column;
        justify-content: center ;
        align-items: center ;
        margin: 0px;
        padding-bottom: 5px;
    }

}

%flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}


%hide-scrollbar {
    overflow-y: scroll;
    /* Hide scrollbar  */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

%visually-hidden-placeholder {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

%placeholderH1 {
    background-color: #000;
    color: #fff;
    z-index: 5;
    text-align: center;
}
